<template>
  <v-container fluid>
    <br />
    <v-row>
      <v-col md="6">
        <v-card>
          <v-card-title primary-title>{{ $t("currentMotion") }}</v-card-title>
          <v-card-text>
            <v-card v-if="motionList[0]" rounded="l">
              <v-card-title primary-title v-if="motionList[0].type === 'mod'">{{
                $t("mod")
              }}</v-card-title>
              <v-card-title
                primary-title
                v-if="motionList[0].type === 'unmod'"
                >{{ $t("unmod") }}</v-card-title
              >
              <v-card-title
                primary-title
                v-if="motionList[0].type === 'custom'"
                >{{ motionList[0].topic }}</v-card-title
              >
              <v-card-title>
                <v-list-item-avatar>
                  <v-img :src="getImage(motionList[0].country.code)"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    <span v-if="$root.$i18n.locale === 'zh'">
                      {{ motionList[0].country.name_zh }}
                    </span>
                    {{ motionList[0].country.name_en }}</v-list-item-title
                  >
                </v-list-item-content>
                <br />
              </v-card-title>
              <v-card-text>
                <div v-if="motionList[0].topic">
                  <span>{{ $t("topic") }}:</span>
                  {{ motionList[0].topic }}
                </div>
                <br />
                <div v-if="motionList[0].durationMin">
                  <span>{{ $t("totalTime") }}:</span>
                  {{ motionList[0].durationMin }}:{{
                    motionList[0].durationSec | doubleDigits
                  }}
                  {{ $t("min") }}
                </div>
                <br />
                <div v-if="motionList[0].indDurationMin">
                  <span> {{ $t("everyDelegate") }}: </span>
                  {{ motionList[0].indDurationMin }}:{{
                    motionList[0].indDurationSec | doubleDigits
                  }}
                  {{ $t("min") }}
                </div>
              </v-card-text>
              <v-card-actions>
                <v-row>
                  <v-col>
                    <v-btn color="success" block @click="passMotion">{{
                      $t("pass")
                    }}</v-btn>
                  </v-col>
                  <v-col>
                    <v-btn color="error" block @click="failMotion">{{
                      $t("fail")
                    }}</v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
            <div v-else>{{ $t("noMotion") }}</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="6">
        <v-card>
          <v-container style="max-height: 700px" class="overflow-y-auto">
            <v-card-title primary-title>
              {{ $t("motion") }}
              <v-spacer></v-spacer>
              <v-btn color="secondary" outlined small to="/history">{{
                $t("motionHistory")
              }}</v-btn>
            </v-card-title>
            <v-card-text>
              <div v-if="motionList.slice(1).length === 0">
                {{ $t("noMotion") }}
              </div>
              <v-card
                v-for="(item, index) in motionList.slice(1)"
                :key="index"
                rounded="l"
                class="mb-4"
              >
                <v-card-title primary-title v-if="item.type === 'mod'">
                  {{ $t("mod") }}</v-card-title
                >
                <v-card-title primary-title v-if="item.type === 'unmod'">
                  {{ $t("unmod") }}</v-card-title
                >
                <v-card-title primary-title v-if="item.type === 'custom'">{{
                  item.topic
                }}</v-card-title>
                <v-card-title>
                  <v-list-item-avatar v-if="item.country">
                    <v-img
                      :src="getImage(item.country && item.country.code)"
                    ></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      <span v-if="$root.$i18n.locale === 'zh'">
                        {{ item.country && item.country.name_zh }}
                      </span>
                      {{
                        item.country && item.country.name_en
                      }}</v-list-item-title
                    >
                  </v-list-item-content>
                  <br />
                </v-card-title>
                <v-card-text>
                  <div v-if="item.topic">
                    <span> {{ $t("topic") }}:</span>
                    {{ item.topic }}
                  </div>
                  <br />
                  <div v-if="item.durationMin">
                    <span> {{ $t("totalTime") }} :</span>
                    {{ item.durationMin }}:{{ item.durationSec | doubleDigits }}
                    {{ $t("min") }}
                  </div>
                  <br />
                  <div v-if="item.indDurationMin">
                    <span> {{ $t("everyDelegate") }}:</span>
                    {{ item.indDurationMin }}:{{
                      item.indDurationSec | doubleDigits
                    }}
                    {{ $t("min") }}
                  </div>
                </v-card-text>
              </v-card>
            </v-card-text>
          </v-container>
          <v-card-actions>
            <v-row>
              <v-col>
                <v-btn
                  color="error"
                  block
                  text
                  @click="resetMotion"
                  class="mx-1 mb-1"
                >
                  {{ $t("clearAll") }}</v-btn
                >
                <v-dialog v-model="dialog" max-width="500px">
                  <template v-slot:activator="{ on }">
                    <v-btn color="secondary" block v-on="on" class="mx-1">
                      {{ $t("newMotion") }}</v-btn
                    >
                  </template>
                  <v-card>
                    <v-toolbar dark color="secondary">
                      <v-btn icon dark @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                      <v-toolbar-title> {{ $t("newMotion") }}</v-toolbar-title>
                      <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-tabs v-model="tab" centered>
                      <v-tab v-for="item in tabs" :key="item.tab">
                        {{ item.tab }}
                      </v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="tab">
                      <v-tab-item>
                        <v-card>
                          <v-form v-model="valid" ref="form">
                            <v-card-text>
                              <v-autocomplete
                                v-model="mod.country"
                                :items="countriesPresent"
                                :filter="countryFilter"
                                hide-no-data
                                hide-selected
                                :label="$t('submittingCountry')"
                                prepend-icon="mdi-earth"
                                return-object
                                outlined
                                :multiple="false"
                                :rules="requiredRule"
                              >
                                <template v-slot:selection="{ item }">
                                  <v-list-item-content>
                                    <v-list-item-title
                                      v-if="$root.$i18n.locale === 'zh'"
                                      v-text="item.name_zh"
                                    ></v-list-item-title>
                                    <v-list-item-subtitle
                                      v-text="item.name_en"
                                    ></v-list-item-subtitle>
                                  </v-list-item-content>
                                </template>
                                <template v-slot:item="{ item }">
                                  <v-list-item-avatar>
                                    <img
                                      :src="getImage(item.code)"
                                      alt="item.name_en"
                                    />
                                  </v-list-item-avatar>
                                  <v-list-item-content>
                                    <v-list-item-title
                                      v-if="$root.$i18n.locale === 'zh'"
                                      v-text="item.name_zh"
                                    ></v-list-item-title>
                                    <v-list-item-subtitle
                                      v-text="item.name_en"
                                    ></v-list-item-subtitle>
                                  </v-list-item-content>
                                </template>
                              </v-autocomplete>
                              <v-text-field
                                :label="$t('topic')"
                                v-model="mod.topic"
                                outlined
                                prepend-icon="mdi-message-text"
                              ></v-text-field>
                              <v-divider></v-divider>
                              <p>{{ $t("totalTime") }}</p>
                              <v-row>
                                <v-col>
                                  <v-text-field
                                    :label="$t('min')"
                                    :hint="$t('min')"
                                    v-model="mod.durationMin"
                                    outlined
                                    prepend-icon="mdi-clock"
                                    :rules="minRules"
                                  ></v-text-field>
                                </v-col>
                                <v-col>
                                  <v-text-field
                                    :label="$t('sec')"
                                    :hint="$t('sec')"
                                    v-model="mod.durationSec"
                                    outlined
                                    :rules="secondRules"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-divider></v-divider>
                              <p>{{ $t("everyDelegate") }}</p>
                              <v-row>
                                <v-col>
                                  <v-text-field
                                    :hint="$t('min')"
                                    :label="$t('min')"
                                    prepend-icon="mdi-clock"
                                    v-model="mod.indDurationMin"
                                    outlined
                                    :rules="minRules"
                                  ></v-text-field>
                                </v-col>
                                <v-col>
                                  <v-text-field
                                    :label="$t('sec')"
                                    :hint="$t('sec')"
                                    v-model="mod.indDurationSec"
                                    outlined
                                    :rules="secondRules"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                            </v-card-text>
                            <v-card-actions>
                              <v-btn
                                color="secondary"
                                @click.native="pushModToMotion"
                                :disabled="!valid"
                                block
                                >{{ $t("confirm") }}</v-btn
                              >
                            </v-card-actions>
                          </v-form>
                        </v-card>
                      </v-tab-item>
                      <v-tab-item>
                        <v-card>
                          <v-form v-model="validUnmod" ref="form2">
                            <v-card-text>
                              <v-autocomplete
                                v-model="unmod.country"
                                :items="countriesPresent"
                                :filter="countryFilter"
                                hide-no-data
                                hide-selected
                                :label="$t('submittingCountry')"
                                prepend-icon="mdi-earth"
                                return-object
                                outlined
                                :rules="requiredRule"
                                ><template v-slot:selection="{ item }">
                                  <v-list-item-content>
                                    <v-list-item-title
                                      v-if="$root.$i18n.locale === 'zh'"
                                      v-text="item.name_zh"
                                    ></v-list-item-title>
                                    <v-list-item-subtitle
                                      v-text="item.name_en"
                                    ></v-list-item-subtitle>
                                  </v-list-item-content>
                                </template>
                                <template v-slot:item="{ item }">
                                  <v-list-item-avatar>
                                    <img
                                      :src="getImage(item.code)"
                                      alt="item.name_en"
                                    />
                                  </v-list-item-avatar>
                                  <v-list-item-content>
                                    <v-list-item-title
                                      v-if="$root.$i18n.locale === 'zh'"
                                      v-text="item.name_zh"
                                    ></v-list-item-title>
                                    <v-list-item-subtitle
                                      v-text="item.name_en"
                                    ></v-list-item-subtitle>
                                  </v-list-item-content> </template
                              ></v-autocomplete>
                              <p>{{ $t("totalTime") }}</p>
                              <v-row>
                                <v-col>
                                  <v-text-field
                                    :label="$t('min')"
                                    :hint="$t('min')"
                                    v-model="unmod.durationMin"
                                    outlined
                                    prepend-icon="mdi-clock"
                                    :rules="minRules"
                                  ></v-text-field>
                                </v-col>
                                <v-col>
                                  <v-text-field
                                    :label="$t('sec')"
                                    :hint="$t('sec')"
                                    v-model="unmod.durationSec"
                                    outlined
                                    :rules="secondRules"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                            </v-card-text>
                            <v-card-actions>
                              <v-btn
                                color="secondary"
                                @click.native="pushUnModToMotion"
                                :disabled="!validUnmod"
                                block
                                >{{ $t("confirm") }}</v-btn
                              >
                            </v-card-actions>
                          </v-form>
                        </v-card>
                      </v-tab-item>
                      <v-tab-item>
                        <v-card flat>
                          <v-form v-model="validCustom" ref="form3">
                            <v-card-text>
                              <v-autocomplete
                                v-model="custom.country"
                                :items="countriesPresent"
                                :filter="countryFilter"
                                hide-no-data
                                hide-selected
                                :label="$t('submittingCountry')"
                                prepend-icon="mdi-earth"
                                return-object
                                flat
                                outlined
                                :rules="requiredRule"
                                ><template v-slot:selection="{ item }">
                                  <v-list-item-content>
                                    <v-list-item-title
                                      v-text="item.name_zh"
                                      v-if="$root.$i18n.locale === 'zh'"
                                    ></v-list-item-title>
                                    <v-list-item-subtitle
                                      v-text="item.name_en"
                                    ></v-list-item-subtitle>
                                  </v-list-item-content>
                                </template>
                                <template v-slot:item="{ item }">
                                  <v-list-item-avatar>
                                    <img
                                      :src="getImage(item.code)"
                                      alt="item.name_en"
                                    />
                                  </v-list-item-avatar>
                                  <v-list-item-content>
                                    <v-list-item-title
                                      v-if="$root.$i18n.locale === 'zh'"
                                      v-text="item.name_zh"
                                    ></v-list-item-title>
                                    <v-list-item-subtitle
                                      v-text="item.name_en"
                                    ></v-list-item-subtitle>
                                  </v-list-item-content> </template
                              ></v-autocomplete>
                              <v-combobox
                                :label="$t('topic')"
                                v-model="custom.topic"
                                flat
                                outlined
                                :items="customTopics"
                                :hint="$t('customHint')"
                                prepend-icon="mdi-message-text"
                                persistent-hint
                                small-chips
                                hide-selected
                              >
                                <template v-slot:no-data>
                                  <v-list-item>
                                    <v-list-item-content>
                                      <v-list-item-title>
                                        {{ $t("createHint") }}
                                      </v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                </template>
                              </v-combobox>
                              {{ $t("totalTime") }}
                              <v-text-field
                                :label="$t('min')"
                                v-model="custom.durationMin"
                                solo
                                :rules="minCustomRules"
                              ></v-text-field>
                              <v-text-field
                                :label="$t('sec')"
                                v-model="custom.durationSec"
                                solo
                                :rules="secondCustomRules"
                              ></v-text-field>
                            </v-card-text>
                            <v-card-actions>
                              <v-btn
                                color="secondary"
                                @click.native="pushCustomToMotion"
                                :disabled="!validCustom"
                                block
                                >{{ $t("confirm") }}</v-btn
                              >
                            </v-card-actions>
                          </v-form>
                        </v-card>
                      </v-tab-item>
                    </v-tabs-items>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  metaInfo: {
    title: "动议",
  },
  filters: {
    doubleDigits: function (val) {
      const formattedNumber = ("0" + val).slice(-2);
      return formattedNumber;
    },
  },
  data() {
    return {
      dialog: false,
      tab: null,
      customTopics: [],
      mod: {
        durationMin: 10,
        durationSec: 0,
        indDurationMin: 1,
        indDurationSec: 0,
      },
      unmod: {
        durationMin: 10,
        durationSec: 0,
      },
      custom: {},
      valid: true,
      validUnmod: true,
      validCustom: true,
      secondRules: [(v) => (/^\d+$/.test(v) && v <= 60) || this.$t("input60")],
      secondCustomRules: [
        (v) => !v || (/^\d+$/.test(v) && v <= 60) || this.$t("input60"),
      ],
      minRules: [(v) => (/^\d+$/.test(v) && v <= 60) || this.$t("input60")],
      minCustomRules: [
        (v) => !v || (/^\d+$/.test(v) && v <= 60) || this.$t("input60"),
      ],
      requiredRule: [(v) => !!v || this.$t("selectCountry")],
      tabs: [
        {
          tab: this.$t("mod"),
        },
        {
          tab: this.$t("unmod"),
        },
        {
          tab: this.$t("custom"),
        },
      ],
    };
  },
  computed: {
    countriesPresent() {
      const list = this.$store.getters.inSessionCountriesPresent;
      return list;
    },
    motionList() {
      return this.$store.state.currentSession.motionList;
    },
  },
  mounted() {
    this.customTopics =
      this.$root.$i18n.locale === "zh"
        ? [
            "开启主发言名单",
            "暂时休会",
            "设置议题",
            "结束辩论",
            "暂时结束辩论",
            "更改发言时间",
            "延置决议草案",
            "取消延置决议草案",
            "更改投票顺序",
            "鼓掌通过",
          ]
        : [
            "Open the Speaker's List",
            "Set the Speaker's Time",
            "Vote by Acclamation",
            "Closure of Debate",
            "Adjournment of the Meeting",
            "Adjournment of Debate",
            "Suspension of the Meeting",
            "Close the Speakers List",
            "Reopen the Speakers List",
          ];
  },
  methods: {
    getImage(code) {
      if (code.length > 3) {
        return require(`../assets/flags/UN.svg`);
      } else {
        return require(`../assets/flags/${code}.svg`);
      }
    },
    countryFilter(item, queryText) {
      const textOne = item.name_zh;
      const textTwo = item.name_en.toLowerCase();
      const textThree = item.code.toLowerCase();
      const searchText = queryText.toLowerCase();
      return (
        textOne.indexOf(searchText) > -1 ||
        textTwo.indexOf(searchText) > -1 ||
        textThree.indexOf(searchText) > -1
      );
    },
    resetMotion() {
      const answer = window.confirm(this.$t("deleteConfirm"));
      if (answer) {
        this.$store.commit("resetMotionList");
      }
    },
    pushModToMotion() {
      var mod = JSON.parse(JSON.stringify(this.mod));
      mod.type = "mod";
      this.$store.commit("setMotionList", mod);
      this.dialog = false;
      this.mod = {
        durationMin: 10,
        durationSec: 0,
        indDurationMin: 1,
        indDurationSec: 0,
      };
    },
    pushUnModToMotion() {
      var unmod = JSON.parse(JSON.stringify(this.unmod));
      unmod.type = "unmod";
      this.$store.commit("setMotionList", unmod);
      this.dialog = false;
      this.unmod = { durationMin: 10, durationSec: 0 };
    },
    pushCustomToMotion() {
      var custom = JSON.parse(JSON.stringify(this.custom));
      custom.type = "custom";
      this.$store.commit("setMotionList", custom);
      this.dialog = false;
      this.custom = { durationMin: 10, durationSec: 0 };
    },
    failMotion() {
      this.$store.commit("saveMotion", "fail");
      this.$store.commit("popMotion");
    },
    passMotion() {
      const motion = this.motionList[0];
      const type = motion.type;
      this.$store.commit("saveMotion", "pass");
      if (type === "mod") {
        this.$store.commit("setMod", motion);
        this.$router.push({ name: "mod", params: { mod: this.motionList[0] } });
      }
      if (type === "unmod") {
        this.$store.commit("setUnmod", motion);
        this.$router.push({
          name: "unmod",
          params: { unmod: this.motionList[0] },
        });
      }
      this.$store.commit("popMotion");
    },
  },
};
</script>


<i18n>
{
  "en": {
    "currentMotion": "Current Motion",
    "motionHistory": "Motion History",
    "motion": "Motion",
    "next": "Next",
    "topic": "Topic",
    "totalTime": "Total Time",
    "min": "Minute",
    "sec": "Second",
    "confirm": "Confirm",
    "continue": "Continue",
    "pass": "Pass",
    "mod": "Mod",
    "unmod": "UnMod",
    "custom": "custom",
    "everyDelegate": "Each Delegate",
    "fail": "Fail",
    "noMotion": "No Motion, Please Add New Ones",
    "clearAll": "Clear All",
    "newMotion": "New Motion",
    "submittingCountry": "Submitting Country",
    "input60": "Please Input Integer within 60 ",
    "customHint":"Select or Input Motions",
    "createHint":"No such motions，tap Enter to create one",
    "selectCountry":"Please Select Submitting Country",
    "pause": "Pause",
    "deleteConfirm":"Are you sure to delete all motions?"
  },
  "zh": {
    "currentMotion": "当前动议",
    "motionHistory": "历史动议",
    "motion": "动议",
    "next": "下一位",
    "topic": "主题",
    "totalTime": "总时长",
    "min": "分钟",
    "sec": "秒",
    "confirm": "确定",
    "everyDelegate": "每位代表",
    "pass": "通过",
    "mod": "有组织核心磋商",
    "unmod": "无组织核心磋商",
    "custom": "自定义",
    "noMotion": "尚无动议",
    "fail": "未通过",
    "continue": "继续",
    "clearAll": "全部取消",
    "newMotion": "新增动议",
    "submittingCountry": "动议国",
    "input60": "输入60以内整数",
    "customHint":"输入或选择已有动议主题",
    "createHint":"没有相关动议，敲回车键新建一个",
    "selectCountry":"请选择国家",
    "pause": "暂停",
    "deleteConfirm":"确认删除所有动议么？"
  }
}
</i18n>